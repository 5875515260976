import { store, history } from 'index'
import { notification } from 'antd'
// import { objToQueryString } from 'utils'

export default async function callApi(url, options = {}) {
  try {
    let apiUrl = url
    //  console.log('in callApi')
    // const baseURL = process.env.REACT_APP_BASE_URL
    //  console.log('baseURL', baseURL)
    if (url.startsWith('/')) {
      // if relative url
      if (typeof options.headers === 'undefined') options = { ...options, headers: {} }
      apiUrl = `${url}`
      // apiUrl = url
      options.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    }
    //  console.log('yoyoing', options)
    const response = await fetch(apiUrl, options)
    if (response.status === 401) {
      store.dispatch({
        type: 'user/LOGOUT',
      })
      notification.error({
        message: 'Logged out!',
        description: 'Your session has expired or you have invalid credentials',
      })
      return null
      // throw new Error('Unauthorized!');
    }

    if (response.status === 403) {
      try {
        const res = await response.json()
        console.log(res.data, '403-----')

        if (res.data.status === 'hold') {
          store.dispatch({
            type: 'user/LOGOUT',
          })
        } else if (res.data.status === 'active') {
          const passingQuery = {}
          const { emailVerified, phoneVerified } = res.data
          if (!emailVerified) {
            passingQuery.email = true
          }
          if (!phoneVerified) {
            passingQuery.phone = true
          }
          // notification.error({
          //   message: 'Error',
          //   description: 'Verification needed',
          // });
          notification.warn({
            message: 'Access Denied!',
            description: 'Verification Needed',
          })
          // const queryParams = objToQueryString(passingQuery)
          // history.replace(`/user-verification?${queryParams}`)
          history.replace('/edit-profile')
          // window.setTimeout(() => {
          //   window.location.href = `/user-verification?${queryParams}`
          // }, 1000)
        }
      } catch (err) {
        console.log(err)
      }
      // store.dispatch({
      //   type: 'user/LOGOUT',
      // })
      return null
      // throw new Error('Unauthorized!');
    }

    //  console.log('status 500')
    if (response.status === 500) {
      //  console.log(response.status, 'status 500')
      // notification.error({
      //   message: 'Internal server error!',
      //   description: 'Please try again later.',
      // })

      const a = await response.json().catch(() => {
        throw new Error('Server error!')
      })

      console.log(a, 'lhklhn')

      notification.error({
        message: a.error || a.message,
      })

      return a
    }
    if (response.ok) {
      const a = await response.json().catch(() => {
        throw new Error('Server error!')
      })
      //  console.log(a)
      return a
    }
    if (!response.ok) {
      const a = await response.json().catch(() => {
        throw new Error('Server error!')
      })
      //  console.log(a)
      const err = new Error(a.error ? a.error : response.statusText)
      err.status = response.status
      throw err
    }
    return null
  } catch (err) {
    //  console.log('catch block' ,err)
    throw err
  }
}
