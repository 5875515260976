/* eslint-disable no-empty */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
import { all, takeEvery, put, call, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
// eslint-disable-next-line import/no-cycle
import {
  loginSocial,
  logoutJwt,
  mockLoadCurrentAct,
  // mockLogin,
  login1,
  // currentAccountJwt,
  // mockLoadCurrentAct,
  // , currentAccount, login, logout,
} from 'services/user'
import { getLeftMenuData, getTopMenuData, getLeftMenuDataForAdmin } from 'services/menu'
import * as constants from '_constants'
import { objToQueryString } from 'utils'
import actions from './actions'

export function* LOGIN({ payload }) {
  const { userName, password } = payload
  //  console.log('redux')
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const success = yield call(login1, userName, password)
  //  console.log('successLogin', success)
  if (success && success.data.usertype !== 3) {
    notification.success({
      message: constants.LOGIN_SUCCESS,
      description: constants.LOGIN_SUCCESS_MESSAGE,
    })

    console.log(success.data.verification, 'ourssss')
    const { emailVerified, phoneVerified, email, verifications, id } = success.data

    console.log(emailVerified, phoneVerified, '')

    // if (!emailVerified || !phoneVerified || (!emailVerified && !phoneVerified)) {
    if (phoneVerified && emailVerified) {
    } else {
      const passingQuery = {}
      if (
        email &&
        (verifications.email || verifications.phone || (verifications.email && verifications.phone))
      ) {
        passingQuery.userName = email

        if (verifications.email) {
          passingQuery.emailVerificationId = verifications.email.id
        }

        if (verifications.phone) {
          passingQuery.phoneVerificationId = verifications.phone.id
        }

        if (id) {
          passingQuery.userId = id
        }
      }

      if (!emailVerified) {
        passingQuery.email = true
      }
      if (!phoneVerified) {
        passingQuery.phone = true
      }

      notification.warn({
        message: 'Verfication needed',
      })

      const queryParams = objToQueryString(passingQuery)
      history.push(`/user-verification?${queryParams}`)
    }

    // }`)

    // window.location.href = `#/user-verification?${queryParams}`
    // }

    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
      payload: {
        user: success,
      },
    })

    console.log(success.data, 'the-data')
    localStorage.setItem('userType', success.data.usertype)
    // localStorage.setItem('isAdmin', success.data.isAdmin)
  } else {
    notification.error({
      message: 'Cannot Login',
    })
  }
}

export function* LOGIN_SOCIAL({ payload }) {
  //  console.log('in social login ')
  //  console.log(payload)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const {
    name,
    email,
    imageUrl: avatar,

    accessToken,
  } = payload
  const response = yield call(loginSocial, email, accessToken)

  if (response) {
    //  console.log(response)
    notification.success({
      message: constants.LOGIN_SUCCESS,
      description: constants.LOGIN_SUCCESS_MESSAGE,
    })
    const { userId: id, role, phone } = response
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id,
        email,
        role,
        name,
        phone,
        avatar,
        authorized: true,
      },
    })
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })
  }
}

export function* GET_MENU_DATA(role) {
  let menuLeftData
  console.log('role', role)
  // role === 'true' ||
  // if (role === 1) {
  //   console.log('the-roless', role)

  menuLeftData = yield call(getLeftMenuDataForAdmin)
  // } else {
  //   menuLeftData = yield call(getLeftMenuData)
  // }

  const menuTopData = yield call(getTopMenuData)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuLeftData,
      menuTopData,
    },
  })
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  // const data = yield call(currentAccountJwt)
  const data = yield call(mockLoadCurrentAct)

  //  console.log('JWTData', data)
  // const data={
  //   user:{

  //   }

  // }
  if (data) {
    const { user } = data
    yield put({
      type: actions.SET_STATE,
      payload: {
        ...user,
        loading: false,
        token: data.token,
        // id: user.id,
        email: user.email,
        role: user.role,
        name: user.name,
        // phone: user.phone,
        authorized: true,
      },
    })
    const userType = localStorage.getItem('userType')
    if (userType) {
      yield GET_MENU_DATA(userType)
    }
    // isAdmin
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        authorized: false,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  yield call(logoutJwt)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGIN_SOCIAL, LOGIN_SOCIAL),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(),
  ])
}
